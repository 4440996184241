<script>
export default {
  name: 'Footer'
}
</script>

<template>
  <div class="footer-area-wrapper reveal-footer theme_bg">
    <div
      class="section-space--pt_30"
    >
      <div class="container">
        <div class="row aling-items-center">
          <div class="col-md-12">
            <div class="horizontal-list text-center">
              <ul>
                <li>
                  <span class="copyright-text">
                    &copy; 2024 <b>المجمع القرآني النسوي</b> في البحرين
                  </span>
                </li>
                <li>
                  <a
                    href="tel:37797911"
                    class="hover-style-link hover-style-link--white"
                    >37797911</a
                  >
                </li>
                <li>
                  <a
                    href="tel:39727915"
                    class="hover-style-link hover-style-link--white"
                    >39727915</a
                  >
                </li>
                <li>
                  <a
                    href="mailto:demo@example.com"
                    class="hover-style-link hover-style-link--white"
                    >m.qurani.n.bh@gmail.com
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
